// App.js
import React, { lazy, Suspense } from 'react';
import { AuthProvider, useSession } from '@descope/react-sdk';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate  } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import CookieConsent from "react-cookie-consent";
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationDE from './components/apps/locales/de/translation.json';
import './app.css';

// Kritische Komponenten direkt importieren
import Main from './components/Main';

// Lazy Loading für nicht kritische Komponenten
const Datenschutz = lazy(() => import('./components/Datenschutz'));
const Impressum = lazy(() => import('./components/Impressum'));
const VorstellungsgespraechUeben = lazy(() => import('./components/VorstellungsgespraechUeben'));
const About = lazy(() => import('./components/wichtiges/About'));
const ContactForm = lazy(() => import('./components/ContactForm'));
const Anmelden = lazy(() => import('./components/Anmelden'));
const FaqSite = lazy(() => import('./components/FaqSite'));
const FragenGenerator = lazy(() => import('./components/apps/fragen-generator/FragenGenerator'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const StripePricingTable = lazy(() => import('./components/wichtiges/StripePricingTable'));
const HowtoVideoSite = lazy(() => import('./components/HowtoVideoSite'));
const BlogPost = lazy(() => import('./components/articles/BlogPost'));
const BlogPage = lazy(() => import('./components/Blog'));
const SimulatorInfo = lazy(() => import('./components/SimulatorInfo'));
const SuccessPage = lazy(() => import('./components/wichtiges/SuccessPage'));

// i18n Initialisierung
i18n
  .use(initReactI18next)
  .init({
    resources: {
      de: {
        translation: translationDE
      },
    },
    lng: "de",
    fallbackLng: "de",
    interpolation: {
      escapeValue: false
    }
  });

const RequireAuth = ({ children }) => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  
  if (isSessionLoading) {
      return <div>Laden...</div>;
  }
  
  if (!isAuthenticated) {
      return <Navigate to="/anmelden" state={{ from: location }} />;
  }

  return <>{children}</>;
};

const loadGoogleAnalytics = () => {
  console.log("Google Analytics script is being loaded...");
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-L0263GW0GY';
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    console.log("Google Analytics script loaded.");
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-L0263GW0GY');
  };
};

const AppInner = () => {
  return (
    <>
      <Header className="header-section d-none d-xl-block" />
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={
            <Suspense fallback={<div>Laden...</div>}>
              <About />
            </Suspense>
          } />
          <Route path="/contact" element={
            <Suspense fallback={<div>Laden...</div>}>
              <ContactForm />
            </Suspense>
          } />
          <Route path="/datenschutz" element={
            <Suspense fallback={<div>Laden...</div>}>
              <Datenschutz />
            </Suspense>
          } />
          <Route path="/impressum" element={
            <Suspense fallback={<div>Laden...</div>}>
              <Impressum />
            </Suspense>
          } />
          <Route path="/anmelden" element={
            <Suspense fallback={<div>Laden...</div>}>
              <Anmelden />
            </Suspense>
          } />
          <Route path="/preise" element={
            <Suspense fallback={<div>Laden...</div>}>
              <StripePricingTable />
            </Suspense>
          } />
          <Route path="/vorstellungsgespraech-ueben" element={
            <Suspense fallback={<div>Laden...</div>}>
              <VorstellungsgespraechUeben />
            </Suspense>
          } />
          <Route path="/faq" element={
            <Suspense fallback={<div>Laden...</div>}>
              <FaqSite />
            </Suspense>
          } />
          <Route path="/bewerbungsfragen-generator" element={
            <Suspense fallback={<div>Laden...</div>}>
              <FragenGenerator />
            </Suspense>
          } />
          <Route path="/interviewsimulator-info" element={
            <Suspense fallback={<div>Laden...</div>}>
              <SimulatorInfo />
            </Suspense>
          } />
          <Route path="/dashboard" element={
            <RequireAuth>
              <Suspense fallback={<div>Laden...</div>}>
                <Dashboard />
              </Suspense>
            </RequireAuth>
          } />
          <Route path="/success" element={
            <RequireAuth>
              <Suspense fallback={<div>Laden...</div>}>
                <SuccessPage />
              </Suspense>
            </RequireAuth>
          } />
          <Route path="/howto" element={
            <Suspense fallback={<div>Laden...</div>}>
              <HowtoVideoSite />
            </Suspense>
          } />
          <Route path="/blog" element={
            <Suspense fallback={<div>Laden...</div>}>
              <BlogPage />
            </Suspense>
          } />
          <Route path="/blog/:id" element={
            <Suspense fallback={<div>Laden...</div>}>
              <BlogPost />
            </Suspense>
          } />
        </Routes>
        <CookieConsent
          buttonText="Zustimmen"
          onAccept={loadGoogleAnalytics}
        >
          Diese Webseite nutzt Cookies, sobald Sie einen Account erstellen.
        </CookieConsent>
      </div>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <AuthProvider projectId="P2RnQ3w9YNRl9YqvkTYyMqNeTPXp">
      <I18nextProvider i18n={i18n}>
        <Router>
          <AppInner />
        </Router>
      </I18nextProvider>
    </AuthProvider>
  );
};

export default App;
